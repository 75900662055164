<template>
    <report
      :isLoad="isLoad"
      :messageApi="messageApi"
      @downLoadExcel="fetchData"
      name="المهمات"
    />
  </template>
  
  <script>
  import report from "./report.vue";
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        isLoad: false,
        messageApi: "",
      };
    },
    components: {
      report,
    },
    computed: {
      ...mapGetters("dataList", ["message", "success_api"]),
    },
    methods: {
      ...mapActions("dataList", ["excelMission"]),
      async fetchData(value) {
        this.isLoad = true;
        await this.excelMission(value);
        this.messageApi = this.message;
        this.isLoad = false;
      },
    },
  };
  </script>
  